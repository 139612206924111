import {React,Component}from 'react';
import { RedirectRoute, Redirect,Route } from 'react-router-dom'
import StudentDash from '../StudentDashboard/StudentDash';
import { toast } from 'react-toastify';
import TenQuiz from '../TenQuiz/TenQuiz';
import TechnicalQuiz from '../QuizQuestions/TechnicalQuiz';
import ReasoningQuiz from '../QuizQuestions/ReasoningQuiz';
import VerbalQuiz from '../QuizQuestions/VerbalQuiz';
import Quiz from '../QuizQuestions/Quiz';
import Question from '../Question/Question';
import Jobs from '../Jobs/Jobs';

class AppWrapper extends Component{
  render(){
    const onFailure = (a) => {
      toast.error(a, {
        position: "top-center",
        hideProgressBar: true,
        draggable: false,
      });
    }
  if(sessionStorage.getItem('token')==null)
  {
    console.log("loginpage");
    let a="LOGIN FIRST !!!";
    onFailure(a);
     return <Redirect to="login" />
     
    }
    else{  
      console.log("studentdash");
         return(
     <div>
        <Route exact path="/studentdash" component={StudentDash} />
        <Route exact path="/quiz" component={Quiz} />
        <Route exact path="/technicalquiz" component={TechnicalQuiz} />
        <Route exact path="/reasoningquiz" component={ReasoningQuiz} />
        <Route exact path="/verbalquiz" component={VerbalQuiz} />
        <Route exact path="/question" component={Question} />
        <Route exact path="/jobs" component={Jobs} />

     </div>
   );
  }
  }
}
export default AppWrapper;