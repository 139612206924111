import React from 'react';
import './TenQuiz.css';
import NavDash from '../StudentDashboard/NavDash';
import { useHistory } from 'react-router';
import { useLocation } from "react-router-dom";

const TenQuiz = ({value,head}) => {
    const location = useLocation();
    console.log(value);
    const history= useHistory();
function handlesubmit(){
    console.log(value)
    // console.log(apti)
    history.push({pathname:'/question',state:{value: value,head:head}})
}
    return (
        <>
            <div>
                {/* <NavDash /> */}

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-10 mx-auto">
                            <h1></h1>
                            <div className="row">

                                <div className="col-md-4 mt-5">
                                    <div className="card w-75 mt-5 TenQuizCaaard">
                                        <div className="card-body">
                                            <h5 className="card-title brand">Quiz Set 1</h5>
                                            <div className="mt-4">
                                                <button type="submit" className="btn btn-outline-primary TenQuizbuttn"
                                                    onClick={handlesubmit}>
                                                    Start Quiz
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
{/* 
                                <div className="col-md-4 mt-5">
                                    <div className="card w-75 mt-5 TenQuizCaaard">
                                        <div className="card-body">
                                            <h5 className="card-title brand">Quiz Set 2</h5>
                                            <div className="mt-4">
                                                <button type="submit" className="btn btn-outline-primary TenQuizbuttn">
                                                    Start Quiz
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 mt-5">
                                    <div className="card w-75 mt-5 TenQuizCaaard">
                                        <div className="card-body">
                                            <h5 className="card-title brand">Quiz Set 3</h5>
                                            <div className="mt-4">
                                                <button type="submit" className="btn btn-outline-primary TenQuizbuttn">
                                                    Start Quiz
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">

                                <div className="col-md-4 mt-5">
                                    <div className="card w-75 mt-5 TenQuizCaaard">
                                        <div className="card-body">
                                            <h5 className="card-title brand">Quiz Set 4</h5>
                                            <div className="mt-4">
                                                <button type="submit" className="btn btn-outline-primary TenQuizbuttn">
                                                    Start Quiz
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 mt-5">
                                    <div className="card w-75 mt-5 TenQuizCaaard">
                                        <div className="card-body">
                                            <h5 className="card-title brand">Quiz Set 5</h5>
                                            <div className="mt-4">
                                                <button type="submit" className="btn btn-outline-primary TenQuizbuttn">
                                                    Start Quiz
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 mt-5">
                                    <div className="card w-75 mt-5 TenQuizCaaard">
                                        <div className="card-body">
                                            <h5 className="card-title brand">Quiz Set 6</h5>
                                            <div className="mt-4">
                                                <button type="submit" className="btn btn-outline-primary TenQuizbuttn">
                                                    Start Quiz
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-5" >

                                <div className="col-md-4 mt-5">
                                    <div className="card w-75 mt-5 TenQuizCaaard">
                                        <div className="card-body">
                                            <h5 className="card-title brand">Quiz Set 7</h5>
                                            <div className="mt-4">
                                                <button type="submit" className="btn btn-outline-primary TenQuizbuttn">
                                                    Start Quiz
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 mt-5">
                                    <div className="card w-75 mt-5 TenQuizCaaard">
                                        <div className="card-body">
                                            <h5 className="card-title brand">Quiz Set 8</h5>
                                            <div className="mt-4">
                                                <button type="submit" className="btn btn-outline-primary TenQuizbuttn">
                                                    Start Quiz
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 mt-5">
                                    <div className="card w-75 mt-5 TenQuizCaaard">
                                        <div className="card-body">
                                            <h5 className="card-title brand">Quiz Set 9</h5>
                                            <div className="mt-4">
                                                <button type="submit" className="btn btn-outline-primary TenQuizbuttn">
                                                    Start Quiz
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                    
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TenQuiz;