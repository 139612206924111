import React from 'react';
import './Footer.css';
import { NavLink } from 'reactstrap';
import { SocialIcon } from 'react-social-icons';

const Footer = () => {
    return(
        <>
        <section id= "footer" className= "d-flex aligmn-item-center">
            <div className="container-fluid nav_bg">
                <div className='row mt-5 foooter'>
                    <div className= "col-10 mx-auto">
                    <div className="row my-3">
                    <div className="col-md-6 mt-1 pt-lg-0 order-2 order-lg-1 
                           d-flex justify-content-center flex-column ">
                        <h4>Contact Us</h4>                         
                        <a href = "mailto: abc@example.com">Email:Placementera@placementera.com</a>
                        {/* <a href="tel:5554280940">Call us at 111111111</a>  */}
                   </div>            

                    <div className="col-md-6 mt-1 pt-lg-0 order-2 order-lg-1 
                            d-flex justify-content-center flex-column">
                         <h4>Social media</h4>
                         <div>
                        <SocialIcon url="https://www.facebook.com/jaketrent" />
                        <SocialIcon url="https://linkedin.com/in/jaketrent" />
                        <SocialIcon url="https://www.instagram.com//jaketrent" />
                        <SocialIcon url="https://telegram.org/jaketrent" />
                        </div>                       
                    </div>      
                    </div>
                    </div> 
                </div>                    
            </div>
        </section>
        <div className="container-fluid nav_bg foo">
            © 2021 Placement Era Pvt Ltd – All Rights Reserved
        </div>
     </>                   
    );
}
export default Footer;
