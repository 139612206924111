import React from 'react';
import './Logo.css';

import peLogo from "../../assets/images/PE_Logo-01.svg";
const Logo = (props) => (
    <div>
        <img className="img-set" src= {peLogo} alt="PlacementEra" />
    </div>
);

export default Logo;