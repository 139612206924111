import React, { useState } from 'react';
import NavDash from './NavDash';
import Footer from '../Home/Footer';
import { useHistory } from 'react-router';
import './StudentDash.css';
import axios from 'axios';

const StudentDash = (props) => {
    console.log("i am student dash board")
    const [apti] = useState('Aptitude Quizes');
    const [tech] = useState('Technical Quizes');
    const [ver] = useState('Verbal Quizes');
    const [reas] = useState('Reasoning Quizes');

     const history= useHistory();

     function aptitude () {
         history.push({pathname:'/quiz',state:{apti: apti}});
     };
      function reasoning () {
         history.push({pathname:"/reasoningquiz",state:{reas: reas}});
     };
      function technical () {
         history.push({pathname:"/technicalquiz",state:{tech: tech}});
     };
      function verbal () {
         history.push({pathname:"/verbalquiz",state:{ver: ver}});
     };
    return (
        <>
            <NavDash />
            {/* <Helmet bodyAttributes={{ style: 'background-color: #99d6ff' }}></Helmet> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-10 mx-auto">

                        <div className="row">
                            <div className="col-md-6 mt-5">
                                <div className="card w-75 mt-5 StudCaard">
                                    <div className="card-body">
                                        <h5 className="card-title brand">{apti}</h5>
                                        <div className="mt-4">
                                            <button type="submit" className="btn btn-outline-primary Studbutn" 
                                            onClick={aptitude}>
                                                Start 
                                            </button>
                                        </div>
                                        {/* <a href="#" className="btn btn-primary">Button</a> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 mt-5">
                                <div className="card w-75 mt-5 StudCaard">
                                    <div className="card-body">
                                        <h5 className="card-title brand">{ver}</h5>
                                        <div className="mt-4">
                                            <button type="button" onClick={verbal} className="btn btn-outline-primary Studbutn">
                                                Start
                                            </button>
                                        </div>
                                        {/* <a href="#" className="btn btn-primary">Button</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-10 mx-auto">

                        <div className="row">
                            <div className="col-md-6 mt-5">
                                <div className="card w-75 mt-5 StudCaard">
                                    <div className="card-body">
                                        <h5 className="card-title brand">{reas}</h5>
                                        <div className="mt-4">
                                            <button type="button" onClick={reasoning} className="btn btn-outline-primary Studbutn">
                                                Start
                                            </button>
                                        </div>
                                        {/* <a href="#" className="btn btn-primary">Button</a> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 mt-5">
                                <div className="card w-75 mt-5 StudCaard">
                                    <div className="card-body">
                                        <h5 className="card-title brand">{tech}</h5>
                                        <div className="mt-4">
                                            <button type="button" onClick={technical} className="btn btn-outline-primary Studbutn">
                                                Start
                                            </button>
                                        </div>
                                        {/* <a href="#" className="btn btn-primary">Button</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          <Footer/>  
        </>
    );
}

export default StudentDash;