import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Account from './components/Login/Account';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  //<React.StrictMode>
  <>
  <BrowserRouter>
  
  <Account>
     <App />
   </Account>
  
    
  </BrowserRouter>
  </>,
  //</React.StrictMode>,
  document.getElementById('root')
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
