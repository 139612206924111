import React from 'react';
import Footer from '../Home/Footer';
import NavDash from '../StudentDashboard/NavDash';
import Maintenance from '../Logo/Maintenance';

const Jobs = () => {
    return(
        <>
        <div>
            <NavDash />
            <div className="container-fluid nav_bg">
                <div className='row'>
                    <div className= "col-10 mx-auto">
                         <Maintenance/>
                    </div>
                </div>
           </div>

            <Footer/>
        </div>
    </>
);
  
}

export default Jobs;