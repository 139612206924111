import React, { useState, useContext, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Logo from '../Logo/Logo';
import './NavDash.css';
import { AccountContext } from '../Login/Account';

const NavDash = () => {
    const { getSession, logout } = useContext(AccountContext);
  useEffect(() => {
    getSession()
      .then(session => {
        console.log('Session:', session);
          })
  }, []);

 
    return (
        <>
            <div className="container-fluid nav_bg">
                <div className='row'>
                    <div className= "col-10 mx-auto">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <div className="container-fluid">
                            <div className="col-7 mt-3" >
                             <NavLink className="navbar-brand mt-2" to="/home">
                                 <Logo />
                             </NavLink>
                             </div>
                            
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                               
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                                        <li className="nav-item">
                                        <i class="fas fa-user-alt"></i>
                                        <span class="glyphicon glyphicon-user"></span>
                                            <NavLink className="nav-link active text" aria-current="page" to="/studentdash">
                                                My Dashboard 
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                        <i class="fas fa-user-alt"></i>
                                        <span class="glyphicon glyphicon-user"></span>
                                            <NavLink className="nav-link active text" aria-current="page" to="/jobs">
                                                Find Job        
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                        <i class="fas fa-user-alt"></i>
                                            <NavLink className="nav-link text" aria-current="page" to="/studentdash">
                                                My Profile
                                            </NavLink>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink className="nav-link text" onClick={logout} to="/login"  >Logout </NavLink>
                                        </li>

                                        {/* <li className="nav-item">
                                            <NavLink className="nav-link text" to="/about">About </NavLink>
                                        </li> */}

                                        {/* <li className="nav-item">
                                            <NavLink className="nav-link text" to="/contact">Contact </NavLink>
                                        </li> */}
                                     </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NavDash;