import React from 'react';
import './Home.css';
import Headimg from '../Logo/Headimg';
import Navbar from './Navbar';
import Footer from './Footer';

const Home = () => {
    return(
        <>
        <Navbar />
            <section id= "header" className= "d-flex ">
            <div className="container-fluid nav_bg">
                <div className='row'>
                    <div className= "col-10 mx-auto">
                        <div className="row mt-3">
                        <div className="col-md-6 mt-5 pt-5 pt-lg-0 order-2 order-lg-1 
                            d-flex justify-content-center flex-column">
                            <h1 className="font-h1" >
                            Start your placement training with us
                           </h1>
                              <p className= "font mt-3">
                                We provide you many quizes in different areas that help you in company
                                recruitment process like Aptitude, Verbal, Reasoning and Technical areas.
                                
                                We have knowledge in all above areas but lack of practice we don't ready for 
                                company recruitment process.

                                 At Placement Era you find the way that what actually
                                company ask what level of questions they ask and practice much more according it.
                                So join Us and get your dream job.  
                              </p> 
                             </div> 

                             <div className="col-lg-6 mt-5 order-1 order-lg-2"> 
                            <Headimg />
                            </div>
                     </div>
                    </div>
                </div>

                <div className="container-fluid">
                            <div className="row">
                                <div className="col-10 mx-auto">

                                    <div className="row">
                                    <div className="col-md-6 mt-5">
                                         <div className="card w-75 mt-5 Caard">
                                         <div className="card-body">
                                        <h5 className="card-title brand">Quantitative Aptitude Quiz</h5>
                                    <div className="mt-4">
                                        <button type="button" className="butn btn btn-outline-primary">
                                             To know more
                                        </button>
                                    </div>
                                     {/* <a href="#" className="btn btn-primary">Button</a> */}
                                </div>
                            </div>  
                            </div>
                                    
                            <div className="col-md-6 mt-5">
                                 <div className="card w-75 mt-5 Caard">
                                <div className="card-body">
                                    <h5 className="card-title brand">Verbal Ability Quiz</h5>
                                    <div className="mt-4">
                                     <button type="button" className="btn btn-outline-primary butn">
                                         To know more
                                    </button>
                                    </div>
                                </div>
                            </div>
                         </div>
                     </div>
                     </div> 
                     </div> 
                   </div> 

                   <div className="container-fluid">
                        <div className="row">
                        <div className="col-10 mx-auto">

                        <div className="row">
                         <div className="col-md-6 mt-5">
                              <div className="card w-75 mt-5 Caard">
                                <div className="card-body">
                                    <h5 className="card-title brand">Logical Reasoning Quiz</h5>
                                    <div className="mt-4">
                                         <button type="button" className="btn btn-outline-primary butn">
                                             To know more
                                        </button>
                                    </div>    
                                </div>
                            </div>   
                         </div>

                         <div className="col-md-6 mt-5">
                            <div className="card w-75 mt-5 Caard"> 
                                 <div className="card-body">
                                    <h5 className="card-title brand">Technical Quiz</h5>
                                    <div className="mt-4">
                                     <button type="button" className="btn btn-outline-primary butn">
                                         To know more
                                     </button>
                                     </div>
                                 </div>
                             </div> 
                         </div>
                    </div>  

                    </div> 
                   </div>  
            </div> 
            </div>
        </section>
        <div className="mt-5">
            <Footer />
        </div>
        </>
    );
}

export default Home;