import React from 'react';
import './Headimg.css';
// import "../../assets/images/final_hm_img.webp";
import web from "../../assets/images/final_hm_img.jpg";
const Headimg = (props) => (
    <div>
        <img src={web}  alt="head img" className="shape h-auto w-100" />
    </div>
);

export default Headimg;
// {require("../../assets/images/final_hm_img.webp")}