import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../Logo/Logo';
import './Navbar.css';

const Navbar = () => {
    return (
        <>
            <div className="container-fluid nav_bg">
                <div className='row'>
                    <div className= "col-10 mx-auto">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <div className="container-fluid">
                                {/* <div className="row"> */}
                                 {/* <div className="col-10"> */}
                                         <div className="col-7 mt-3" >
                                              <NavLink className="navbar-brand mt-2" to="/">
                                               <Logo />
                                              </NavLink>
                                       </div>
                                          <div className="col-1" >  
                                              <button className="navbar-toggler btn"  type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                                <span className="navbar-toggler-icon"></span>
                                          </button>
                                          </div>
                                          
                                  {/* </div> */}
                                {/* </div> */}
                                <div className="collapse navbar-collapse" id="navbarSupportedContent" >
                              
                                    <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                                        <li className="nav-item">
                                            <NavLink className="nav-link active text" aria-current="page" to="/">Home </NavLink>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink className="nav-link text" to="/login">Login </NavLink>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink className="nav-link text" to="/about">About </NavLink>
                                        </li>

                                        {/* <li className="nav-item">
                                            <NavLink className="nav-link text" to="/contact">Contact </NavLink>
                                        </li> */}
                                     </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Navbar;