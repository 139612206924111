import React, { Component, useEffect, useState } from 'react';
import NavDash from '../StudentDashboard/NavDash';
import './Quiz.css';
import Question from '../Question/Question';
import value from '../StudentDashboard/StudentDash';
import axios from 'axios';
import TenQuiz from '../TenQuiz/TenQuiz';

            class ReasoningQuiz extends Component {
            constructor()
            {
              super();
              this.state = { data:[]};
            };

            async componentDidMount(){
               // const headers= {
               //                 'X-api-key': process.env.REACT_APP_API_KEY,
               //                 'authorizationToken':process.env.REACT_APP_AUTHORIZATIONTOKEN,
               //             }

            const response= await fetch(process.env.REACT_APP_API_URL_REAS,
                  {
                   headers:{
                    //    'X-api-key': process.env.REACT_APP_API_KEY,
                       'authorizationToken':process.env.REACT_APP_AUTHORIZATIONTOKEN
                   }
                  })
                  const json = await response.json();
                  this.setState({ data: json });
                  console.log(this.state.data)
           }
        
    
   render(){ 
        return (
            <>
                <NavDash />
                <div className="container-fluid nav_bg">
                    <div className='row'>
                        <div className="col-10 mx-auto">
                            <div>
                            <h1 style={{textAlign:"center"}}>Reasoning Quizes</h1>
                                <>
                                    <TenQuiz value={this.state.data} head="Reasoning Questions" />

                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default ReasoningQuiz;

