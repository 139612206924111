import React, { useState } from 'react';
import './Signup.css';
import {Form, Label, Input} from 'reactstrap';
import Helmet from 'react-helmet';
import UserPool from './UserPool';
import Navbar from '../Home/Navbar';
import { useHistory , NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
//this alert will call when any error occure
const onSuccess=(a)=>
{
  console.log("done hiii")
  toast.info(a,{
    position: "top-center",
    hideProgressBar: true,
    draggable: false,
  });
  toast.warning("Verification Link Sent To Your Email !! Please Verify Your Email !!", {
    position: "top-center",
    hideProgressBar: true,
    draggable: false,
  });
}
const onFailure = (msg) => {
  toast.error(msg, {
    position: "top-center",
    hideProgressBar: true,
    draggable: false,
  });
}
 const Signup = (props) => {
 
  const [globalData,setGlobalData]=useState({
    email:'', 
    password:'',
    confirm_password:'',
    first_name:'',
    last_name:'',
    contact:'',
    college_name:'',
    course:'',
    city:'',
    state:'',
});

  

const history= useHistory();
var check = function() {
  if (document.getElementById('password').value ===
    document.getElementById('confirm_password').value) {
    document.getElementById('message').innerHTML = ' ';
  } else {
    document.getElementById('message').style.color = 'red';
    document.getElementById('message').innerHTML = '* Password and Confirm Password must be same';
  }
}
function signup(event)
{
    let  first_name={
        Name:'custom:first_name',
        Value:globalData.first_name
    }
    let  last_name={
      Name:'custom:last_name',
      Value:globalData.last_name
  }
  let  contact={
    Name:'custom:contact',
    Value:globalData.contact  
  }
    let  college_name={
    Name:'custom:college_name',
    Value:globalData.college_name 
  }
  let  course={
    Name:'custom:course',
    Value:globalData.course
  }
  let  city={
    Name:'custom:city',
    Value:globalData.city
  }
  let  state={
    Name:'custom:state',
    Value:globalData.state
  }
  
  
    var attributesList=[];
    attributesList.push(first_name);
    attributesList.push(last_name);
    attributesList.push(contact);
    attributesList.push(college_name);
    attributesList.push(course);
    attributesList.push(city);
    attributesList.push(state);
    console.log(attributesList,'hello');
    event.preventDefault();
    UserPool.signUp(globalData.email,globalData.password,attributesList,null,function(err,data){
      
            
   
            if(err)
        {
          
            console.log(err)
            onFailure(err.message);                          
        }
        else
        {
          let a="REGISTERED SUCCESSFULLY"
          onSuccess(a)
          history.push("/login");
        }
    }) 
}

console.log(globalData, 'globalData>>>>>>>>>>')
const setValue = (event) => {
setGlobalData({
  ...globalData,      
  [event.target.name] : event.target.value
})
}

   return (
    
    <div>
      <Navbar />
      
      <Helmet bodyAttributes={{style: 'background-color: #99d6ff'}} />
      
  <Form className="row g-3 mb-5 Ressignup Stylesignup nav-color" onSubmit={signup}>
    <h1>Sign Up</h1>
    <p style={{ color: 'red' }} className="Valid">ALL FIELDS ARE MUST REQUIRED</p>
  <div className="col-sm-6 mt-4">
    <Label className="form-label Valid">First Name</Label>
    <Input type="text" name="first_name" className="form-control" onChange={(e)=> setValue(e)} required 
       />
  </div> 
  <div className="col-sm-6 mt-4 ">
    <Label className="form-label Valid">Last Name</Label>
    <Input type="text" name="last_name" className="form-control" onChange={(e)=> setValue(e)}required 
        />
  </div> 
  <div className="col-sm-6 ">
    <Label className="form-label Valid">Email</Label>
    <Input type="email" name="email" className="form-control" onChange={(e)=> setValue(e)}required />
  </div>
  <div className="col-sm-6">
    <Label  className="form-label Valid">Contact</Label>
    <Input type="text" name="contact" className="form-control" onChange={(e)=> setValue(e)}required />
  </div>
  <div className="col-sm-6">
    <Label className="form-label Valid">College Name</Label>
    <Input type="text" name="college_name" className="form-control" onChange={(e)=> setValue(e)}required />
  </div>
  <div className="col-sm-6">
    <Label  className="form-label Valid">Course</Label>
    <select name="course" className="form-select" onChange={(e)=> setValue(e)}required>
      <option selected>Select course</option>
      <option>B.Sc</option>
      <option>BCA</option>
      <option>M.Sc</option>
      <option>MCA</option>
      <option>IMCA</option>
      <option>B.E.</option>
      <option>M.E.</option>
      <option>B.Tech</option>
      <option>M.Tech</option>
      <option>Other</option>

    </select>
  </div>
  <div className="col-sm-6">
    <Label className="form-label Valid">City</Label>
    <Input type="text" name="city" className="form-control" onChange={(e)=> setValue(e)}required />
  </div>
  <div className="col-sm-6">
    <Label className="form-label Valid">State</Label>
    <Input type="text" name="state" className="form-control" onChange={(e)=> setValue(e)}required />
  </div>
  <div className="col-sm-6">
    <Label className="form-label Valid">Password</Label>
    <Input type="password" name="password" id="password"  className="form-control"  onChange={(e)=> setValue(e)}required />
  </div>
  <div className="col-sm-6">
    <Label  className="form-label Valid">Confirm Password</Label>
    <Input type="password"  name="confirm_password" id="confirm_password" className="form-control"  onChange={(e)=> setValue(e)}required onKeyUp={check} />
  </div>
  <div>
  <p><span id='message'></span></p>
  </div>
  
  <div className="col-12">
    <button type="submit" className="btn btn-primary">SIGN UP</button>
  </div>
  <div className="mt-2">
            Already have an account? <NavLink to="/login">Log In</NavLink>

          </div>
  <div >
  <p className="Password">
  * First letter of the password should be capital.</p>
  <p className="Password">
  * Password must contain a special character (@, $, !, &, etc).</p>
  <p className="Password">
  * Password length must be greater than 8 characters.</p>
  <p className="Password">
  * A password should be alphanumeric.</p>
  {/* Password must contain a special character (@, $, !, &, etc).<br/>
  Password length must be greater than 8 characters.<br/>
  A password should be alphanumeric.<br/>
  </p> */}
  </div>
  
</Form>



  </div>
  
  );

}
export default Signup;