import React from 'react';
import Footer from '../Home/Footer';
import Navbar from '../Home/Navbar';
import Maintenance from '../Logo/Maintenance';

const Contact = () => {
    return(
        <>
            <Navbar />
            <div className="container-fluid nav_bg">
                <div className='row'>
                    <div className= "col-10 mx-auto">
                         <Maintenance/>
                    </div>
                </div>
           </div>
            <Footer/>
        </>
    );
}

export default Contact;