import React from 'react';
import Navbar from '../Home/Navbar';
import Footer from '../Home/Footer';
import AboutUs from '../Logo/AboutUs';
import './About.css';
import Maintenance from '../Logo/Maintenance';
import { Link } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';

const About = () => {
    return(
        <>
            <Navbar />
            <div className="container-fluid nav_bg">
                <div className='row Aboutfont Aboutfont-h1'>
                    <div className= "col-10 mx-auto">
                        <h1 style={{textAlign: 'center'}}>About Us</h1>
                        <AboutUs />
                        {/* <Maintenance /> */}
                    </div>
                    <h2 style={{textAlign: 'center'}} className="mt-5">Contact Us</h2>
                    <p  style={{textAlign: 'center'}} className="mt-3 Aboutfont-h1">
                        If you have any query or you want any help from us you can email 
                        freely on this  </p>
                    <a style={{textAlign: 'center'}} href = "mailto: Placementera@placementera.com">
                         Placementera@placementera.com
                    </a>
                    <p  style={{textAlign: 'center'}} className="mt-3 Aboutfont-h1"> 
                        and also you connect with us from social media 
                    </p>
                    <div style= {{textAlign: 'center'}} className="mt-3 mb-5">
                        <SocialIcon url="https://www.facebook.com/jaketrent" />
                        <SocialIcon url="https://linkedin.com/in/jaketrent" />
                        <SocialIcon url="https://www.instagram.com/placementera/" />
                        <SocialIcon url="https://telegram.org/jaketrent" />
                    </div>  
                </div>
                {/* <h1 style={{textAlign: 'center'}}>About Us</h1> */}
           </div>
            {/* <Footer/> */}
        </>
    );
}

export default About;