import React, { useState } from 'react';
import '../QuizQuestions/Quiz.css';
import { Button, ButtonGroup } from 'reactstrap';
import axios from 'axios';
import { useHistory } from 'react-router';
import value from '../TenQuiz/TenQuiz';
import { toast } from 'react-toastify';
import { useLocation } from "react-router-dom";
import NavDash from '../StudentDashboard/NavDash';
import { useCallback, MouseEvent } from 'react'
const Question = ({value}) => {
    const location = useLocation();

    console.log(location.state.value)

    const ques = useState("")

    const [colors, setColour] = useState('')
    const [currentQues, setCurrentQues] = useState(0);
    
    const [showScore, setShowScore] = useState(false);

    const [clr,setclr] = useState('');


    const [score, setScore] = useState(0);
    const next = () =>{
        setclr("")
        document.getElementById("optionA").disabled = false;
        document.getElementById("optionB").disabled = false;
        document.getElementById("optionC").disabled = false;
        document.getElementById("optionD").disabled = false;
        document.getElementById("optionA").style.background='#252d4a';
        document.getElementById("optionB").style.background='#252d4a';
        document.getElementById("optionC").style.background='#252d4a';
        document.getElementById("optionD").style.background='#252d4a';

        const nextQuestion = currentQues + 1;
        if (nextQuestion < location.state.value.Items.length) {
            setCurrentQues(nextQuestion);
            console.log(location.state.value.Items[currentQues].ques)
           
        } else {
            setShowScore(true);
        }
    }
    const previous = () =>{
        setclr("")
        document.getElementById("optionA").disabled = false;
        document.getElementById("optionB").disabled = false;
        document.getElementById("optionC").disabled = false;
        document.getElementById("optionD").disabled = false;
        document.getElementById("optionA").style.background='#252d4a';
        document.getElementById("optionB").style.background='#252d4a';
        document.getElementById("optionC").style.background='#252d4a';
        document.getElementById("optionD").style.background='#252d4a';
        const preQuestion = currentQues - 1;
        if(preQuestion < 0){
            //alert("")
            toast.warning('Not Allowed',{
                position:'top-right'
            });
        }
        else{
        if (preQuestion < location.state.value.Items.length) {
            setCurrentQues(preQuestion);
        } 
    }
    }
    // const ans = {
    //     backgroundColor: clr
    //   };
     
    
    const handleAnswerButtonClick = (isCorrect,event) => {
        document.getElementById("optionA").disabled = true;
        document.getElementById("optionB").disabled = true;
        document.getElementById("optionC").disabled = true;
        document.getElementById("optionD").disabled = true;
        if (isCorrect === location.state.value.Items[currentQues].answer) {
            if(score != (currentQues+1)){
                // setclr("green");
                // cc.style.backgroundColor="green";
                document.getElementById(event.target.id).style.background='green';
            setScore(score + 1);  

            }
        }
        else{
            // setclr("red");
            // cc.style.backgroundColor="red";
            setclr("Answer : "+location.state.value.Items[currentQues].answer)
            document.getElementById(event.target.id).style.background='red';

        }
            
    }
    const [answerOption,setAnswerOption]=useState('');
    if(location.state.value.Items===undefined)
    {
        return 0;
    }
    else{
    return (
        <>
         <NavDash />
         <div>
             <h1 style={{textAlign:"center"}}>{location.state.head}</h1>
         </div>
        <div className="app quiz-body">
            {showScore ? (
                <div className="score-section"> Your scored {score} out of {location.state.value.Items.length}</div>
            ) : (
                <>
                    <div className="question-section">
                        <div className="question-count">
                            <span>Question {currentQues + 1}</span>
                        </div>

                        <div className="question-text">
                            {/* {ques[currentQues].question} */}
                            {/* {value.Items} */}
                            {location.state.value.Items[currentQues].ques}
                        </div>
                        <div className="container">
                            <div>
                             {console.log(location.state.value.Items[currentQues])}
                        <button className="opButton" style={{width: '100px'}} onClick={() => next()}>next</button>
                        </div>
                        <div>
                        <button className="opButton" style={{width:'100px'}} onClick={() => previous()}>previous</button>
                        </div>
                        </div>
                    </div>
                    
                    <div className="answer-section ">
                                    
                           <button className="opButton" id="optionA"
                           onClick={(e) => handleAnswerButtonClick(location.state.value.Items[currentQues].optionA,e)}>
                            {location.state.value.Items[currentQues].optionA}
                            </button> 
                             <button className="opButton" id="optionB"  onClick={(e) => handleAnswerButtonClick(location.state.value.Items[currentQues].optionB,e)}>
                            {location.state.value.Items[currentQues].optionB}
                            </button>
                            <button className="opButton" id="optionC" onClick={(e) => handleAnswerButtonClick(location.state.value.Items[currentQues].optionC,e)}>
                            {location.state.value.Items[currentQues].optionC}
                            </button>
                            <button className="opButton" id="optionD" onClick={(e) => handleAnswerButtonClick(location.state.value.Items[currentQues].optionD,e)}>
                            {location.state.value.Items[currentQues].optionD}
                            </button>
                            <h3 style={{textAlign: "center"}}>{clr}</h3>
                    </div>
                </>
            )}
        </div>
    </>
    );
}
}
export default Question;
// "https://vl3env28w7.execute-api.us-east-2.amazonaws.com/production/getquestion"
// REACT_APP_API_KEY = "CFcgGpf9pT10ikK7CLoQk6SHeE1cXo7U4UUVjDeh"
