import React, {useState} from 'react';
//import logo from './logo.svg';
import './App.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Home from './components/Home/Home';
import Login from './components/Login/Login';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
// import Navbar from './components/Home/Navbar';
import {Switch, Route, Redirect} from 'react-router-dom';
import Signup from './components/Form/Signup';
import AppWrapper from './components/Login/AppWrapper';
import Quiz from './components/QuizQuestions/Quiz';
import { ToastContainer} from 'react-toastify';
import TenQuiz from './components/TenQuiz/TenQuiz';
// import Maintenance from './components/Logo/Maintenance';

function App() {
  const [questions, setQuestions] = useState();
  

  return (
    <div >
      <ToastContainer/>
      <Switch>
        <Route exact path= "/" component= {Home} />
        <Route exact path= "/login" component= {Login} />
        <Route exact path= "/signup" component= {Signup} />
        <Route exact path= "/about" component= {About} />
        <Route exact path= "/contact" component= {Contact} />
        {/* <Route exact path= "/maintenance" component= {Maintenance} /> */}
        <Route exact path= "/studentdash" component={AppWrapper} />
        <Route exact path="/tenquiz" component={AppWrapper} />
        <Route exact path= "/quiz" component={AppWrapper}/>
        <Route exact path= "/question" component={AppWrapper}/>
        <Route exact path= "/technicalquiz" component={AppWrapper}/>     
        <Route exact path= "/verbalquiz" component={AppWrapper}/>     
        <Route exact path= "/reasoningquiz" component={AppWrapper}/>    
        <Route exact path= "/jobs" component={AppWrapper} /> 
     
        <Redirect to = "/" />
      </Switch>


      {/* <Signup /> */}


      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;
