import React, {useState , useContext} from 'react';
import { NavLink, useHistory, Redirect } from 'react-router-dom';
import { Form, Label, Input } from 'reactstrap';
import './Login.css';
import { Helmet } from 'react-helmet';
import { AccountContext } from './Account';
import Navbar from '../Home/Navbar';
import Footer from '../Home/Footer';
import { toast } from 'react-toastify';
const onSuccess=(a)=>
{
  console.log("done hiii")
  toast.info(a,{
    position: "top-center",
    hideProgressBar: true,
    draggable: false,
  });
}
const onFailure=(msg)=>{
  toast.error(msg,{
    position: "top-center",
    hideProgressBar: true,
    draggable: false,
  });
}
const Login = (props) => {
  const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');
      
      const {authenticate}  = useContext(AccountContext);

      const history= useHistory();

        const onSubmit = event => {
          event.preventDefault();

          authenticate(email, password)
            .then(data => {
                let a="LOGGED IN SUCCESSFULLY, Welcome..."
                onSuccess(a)
                console.log( 'Logged in!', data);
                sessionStorage.setItem('token',data.idToken.jwtToken);
                history.push("/studentdash");
                
            })
            .catch(err => {
              console.error('Failed to login!', err);
              if(err.message == "User is not confirmed."){
                        onFailure("Please Verify Your Email")
              }
              else{
              onFailure(err.message)
              }
            })
    }
  return (
    <div>
      <Navbar/>
      <Helmet bodyAttributes={{ style: 'background-color: #99d6ff' }}></Helmet>
      <div className="margin-top">
        <Form className="Res Style" onSubmit={onSubmit}>
          <h1>Log In</h1>
          <div className="row mb-3 mt-4">
            <Label className="col-sm-4 col-form-label Valid">Email</Label>
            <div className="col-sm-8">
              <Input type="email" value={email} className="form-control" onChange={event=>setEmail(event.target.value)}required />
            </div>
          </div>
          <div className="row mb-3">
            <Label className="col-sm-4 col-form-label Valid">Password</Label>
            <div className="col-sm-8">
              <Input type="password" value={password} className="form-control" onChange={event=>setPassword(event.target.value)}required />
            </div>
          </div>
          <button type="submit" className="btn btn-primary">Log In</button>
          <div className="mt-2">
            create a new account? <NavLink to="/signup">Sign Up</NavLink>

          </div>
        </Form>
      </div>
    </div>
    
  );
}

export default Login ;
 